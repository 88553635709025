.header-centered {
	@include media-breakpoint-down(xl) {
		padding-right:0;
	}
	.navbar-nav {
		li {
			@include media-breakpoint-up(xl) {
				display:flex;
				align-items:center;
			}
		}
	}
}
@include media-breakpoint-up(xl){
	.theme-logo.header-logo-scroll, .header-position {
		position:absolute;
		right:45%;
		left:38%;
		margin-top:-50px;	
		
	}
}
@include media-breakpoint-up(xxl){
	.theme-logo.header-logo-scroll, .header-position {
		position:absolute;
		right:45%;
		left:38%;
		margin-top:-65px;	
	 }	
	}

#menu-primary-navigation {
	align-items:center !important;
	justify-content:center !important;
  }
  @include media-breakpoint-up(xl){
  .header-default .navbar-nav .nav-link:after, .header-default .navbar-nav .nav-link .nav-item:after{
	  display      : block;
	  content      : '';
	  border-bottom: solid 3px $brand-primary;
	  padding-top: 0px;
	  transform    : scaleX(0);
	  transition   : transform 300ms ease-in-out;
	  transform-origin:center;
	}
	.header-default .navbar-nav .nav-link:hover, .header-default .navbar-nav .nav-link .nav-item:focus{
		  &:after {
			transform: scaleX(1)
		}
	}
  }
  
  @include media-breakpoint-down(xl){
	.header-default .navbar-nav .nav-link.dropdown-toggle::after {
		color: $accent !important;
	  }
	.dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid;
	border-right: 0.3em solid transparent;
	border-bottom: 0;
	border-left: 0.3em solid transparent;
	  }
  }
  

  .p {
	display: inline-block;
	vertical-align: top;
	margin: 0;
  }
  