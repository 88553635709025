// Theme Colors/Branding
$brand-primary: #0458AA;
$brand-primary-rgb: rgba($brand-primary,1);
$brand-primary-offset: darken($brand-primary,10%);
$brand-secondary: #F3383D;
$dark: #07070A;
$light: #f5f5f5;
$medium: #424656;
$inactive: #8c8c8c;
$gray: #888;
$white: #F4F4F9;
$accent:#AAD1FF;
$accent-old:#86BBD8;
$accent-2:#00CCA7;
$body-font: #222;
$error: #F9F871;
$purple: #B574AB; 

.color-accent-2 {
    color:$accent-2;
}

.color-purple {
    color:$purple;
}



.circular-background {
    width: 100%; 
    height: 500px; 
    background: $brand-secondary;
    background-size: cover; 
    clip-path: circle(92.0% at 0 100%);
}
  


.background-accent-2 {
    background: $accent-2 !important;;
}
.background-error {
    background:$error !important;
}
.background-purple {
    background:$purple !important;
}
