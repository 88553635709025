body {
  background: white;
  overflow-x: hidden;
}

.main *::selection {
  color: $white;
  background: $brand-primary;
}

.main *::selection {
  color: $white;
  background: $brand-primary;
}


// Safari Fixes

// phone link
a[href^=tel] {
  text-decoration: inherit;
  color: inherit;
}

//aos
[data-aos*=flip] {
  -webkit-backface-visibility:hidden!important;
}

/** custom classes */

.notunder1500 {
  .btn {
    width: 100%;
    border-radius: 0!important;
  }
}

.background-fixed {
  @include media-breakpoint-up(lg) {
    background-attachment: fixed !important;
  }
}

.cursor-hover {
  &:hover {
    cursor:pointer;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.min-height {
  min-height: 66vw;

  @include media-breakpoint-up(lg) {
    min-height: 50vw;
  }
  @include media-breakpoint-up(xl) {
    min-height: 450px;
  }
}

hr {
  background-color:rgba(0,0,0,0.5);
}

.overflow-hidden {
  overflow: hidden!important;
}

.absolute {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}

.grow-bkg {
  transition: all 0.2s ease-in;

  &.grow-this-bkg,
  .grow-this-bkg {
    background-size: 100%!important;
    transition: all 0.2s ease-in;

    &:hover {
      transition: all 0.2s ease-in;
      background-size: 110%!important;
    }
  }
}

.notunder1500 {
  display: none;
}
@media (min-width:1800px) {
  .notunder1500 {
    display: block;
  }
}

.negative-top {
  margin-top: -100px;
}

// Images

img {
  max-width: 100%;
}

// Util
.img-invert-white {
  filter: brightness(0) invert(1);
}

.grayscale {
  filter: grayscale(1);
}

.z-index-1 {
  z-index:1;
}
.z-index-2 {
  z-index:2;
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, $dark, darken($dark, 10%));
  opacity: 0.85;

  &.light-fade {
    background: linear-gradient(45deg, #000, lighten(#000,10%), #000);
    opacity: 0.6;
  }
}

.sticky {
  @include media-breakpoint-up(md) {
    position: sticky !important;
    top: 160px !important;
  }
}
// Content Images

.img-cover-col {
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}
// RESPONSIVE FIXES

.sticky-top {
  @include media-breakpoint-down(sm) {
    position: static;
  }
}

// Move accessibility button to bottom

.pojo-a11y-toolbar-toggle {
  bottom: 0;
}


#site-topbar .header-social {
  .social-icon {
  color:$white !important; 
  &:hover {
    color:$brand-secondary !important;
  }
}
}
@include media-breakpoint-up(xl){
  .absolute-bg {
      position:absolute;
      left:-46%;
      top:0;
      height:105%;
      width:100%;
      transform: skewX(12deg) !important;
      z-index:0;
      background: linear-gradient(141deg,rgba($accent, 0.64) 20%,rgba($brand-primary, 0.74) 83%,rgba($accent-2, .56));
  }
}

.primary-gradient {
  background: linear-gradient(135deg, rgba($brand-primary, 0.65), rgba($brand-primary, 0.65) 25%, rgba($accent, 0.65) 50%);
}

.background-accent-x {
  background:rgba($accent, 0.65);
}

.background-secondary-x {
  background:rgba($brand-secondary, 0.76);
}
.background-primary-x {
  background:rgba($brand-primary, 0.76);
}
.background-perry {
  background: linear-gradient(11deg,rgba($brand-primary, .85),rgba(0,0,0,.2) 50%,rgba(0,0,0,.2) 60%,rgba(0,0,0,.75));
}
.shadow-sm {
  box-shadow: 1px 2px 3px rgba(0,0,0,.3)!important;
}

.text-shadow { 
  text-shadow:1px 2px 3px rgba(0,0,0,.3)!important;
}


.row-justify-content-between .row {
  justify-content: space-between !important;
}


.right-side-circle {
  clip-path: circle(60.0% at 84% 72%);
}

.corner-logo, .corner-logo img, .corner-service, .corner-service img {
  left: 7%;
  max-width: 65px;
  min-height: 45px;
  top: 7%;
}

.rounded-30 {
  border-radius:30px; 
}

@include media-breakpoint-up(xl){
.splide-mx-xl-0 .splide__track {
  margin-right:0 !important;
  margin-left:0 !important; 
}
}
.splide-mx-0 .splide__track {
  margin-right:0 !important;
  margin-left:0 !important; 
}

.rounded-start {
  border-bottom-left-radius: 30px !important;
  border-top-left-radius: 30px !important;
  @include media-breakpoint-down(md){
    border-bottom-right-radius: 30px !important;
    border-top-right-radius: 30px !important;
  }
}
.rounded-end {
  border-bottom-right-radius: 30px !important;
  border-top-right-radius: 30px !important;
  @include media-breakpoint-down(md){
    border-bottom-left-radius: 30px !important;
    border-top-left-radius: 30px !important;
  }
}
.rounded-start-responsive {
  border-bottom-left-radius: 30px !important;
  border-top-left-radius: 30px !important;
  @include media-breakpoint-down(xl){
    border-bottom-right-radius: 30px !important;
    border-top-right-radius: 30px !important;
  }
}
.rounded-end-responsive {
  border-bottom-right-radius: 30px !important;
  border-top-right-radius: 30px !important;
  @include media-breakpoint-down(xl){
    border-bottom-left-radius: 30px !important;
    border-top-left-radius: 30px !important;
  }
}
.rounded-start-responsive-interior {
  border-bottom-left-radius: 30px !important;
  border-top-left-radius: 30px !important;
  @include media-breakpoint-down(lg){
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
  }
}
.rounded-end-responsive-interior {
  border-bottom-right-radius: 30px !important;
  border-top-right-radius: 30px !important;
  @include media-breakpoint-down(lg){
    border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  }
}




.logo-banner-img {
  max-height:125px; 
}

@include media-breakpoint-up(xl){
  .absolute-bg-right {
      position:absolute;
      right:-46%;
      top:0;
      height:103%;
      width:100%;
      transform: skewX(-12deg) !important;
      z-index:0;
      background: linear-gradient(-141deg, rgba($accent-2, .56) 20%, rgba($brand-primary, 0.74) 83%, rgba($accent, 0.64));
  }
}

.bg-split {
  background:linear-gradient(180deg,$brand-primary 0,$brand-primary 46%,$white 0,$white);
  @include media-breakpoint-up(xl){
    background:linear-gradient(180deg,$brand-primary 0,$brand-primary 34%,$white 0,$white);
  }
}
.bg-split-inverted {
  background:linear-gradient(180deg,$light 0,$light 57%,$brand-primary 0,$brand-primary);
}

.card-img-top {
  min-height:225px; 
}

.contain-img .card-img-top {
  background-size:contain !important;
  min-height:115px;
}

.border-white-large-x {
  border: 15px solid rgba($white, 0.15);
 
}

.background-white-x {
  background:rgba($white, .92);
  backdrop-filter:blur(3px); 
}