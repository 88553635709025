.offcanvas {
  background-attachment:fixed!important;
  .navbar-nav {
    .cta-link {
      margin-top:1em!important;
    }
    .nav-link {
      color:white;
      padding-top:1rem;
      padding-bottom:1rem;
   
      font-size:1.25em;
      font-weight:bold;
      text-transform:none;
      font-family:$heading-font;
    }
    .two-columns .dropdown-menu {
      @include media-breakpoint-down(xl){
        column-count:2;
      }
    }
    .menu-item-has-children {
      & > a::after {
        content: '';
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
      }
    } 
    .dropdown-menu {
      position:relative!important;
      transform:none!important;
      background:transparent;
      border:0;
      border-radius:0;

      .nav-item {
        
        .dropdown-item {
          color:white;
         
          padding: 0.5rem 1rem;
          &:hover {
            background:$dark;
            color:white;
          }
        }
      }
      .menu-item-has-children a {
        color:$accent;
      }
      //2nd level dropdown
      .dropdown-menu {
        margin-left:20px;
        background:lighten($brand-secondary, 18%);
        border-radius:3rem;
        padding-left:10px;
        padding-right:10px;
        .nav-item {
        
          .dropdown-item {
            color:white;
            &:hover {
              background:$accent;
              color:white;
            }
          }
        }
      }
    }
  }
}

.menu-item-61302 a {
  white-space:normal !important;
}