/////////////////////////////////////
// Core Buttons
/////////////////////////////////////

.btn {
  border:0;
  border-radius:30px;
  padding: 0.75rem 1.5rem;
  font-weight:$medium-weight;
  color:white;
  position:relative;
  &:hover {
    color:white;
  }
  &.border-sm {
    border-radius:3px;
  }
  &.border-lg {
    border-radius:10px;
  }
  &.border-xl {
    border-radius:30px;
  }
  &.btn-sm {
    padding: 0.45rem 1rem;
  }
  &.btn-lg {
    padding: 1.25rem 3rem;
  }
}

// Primary
/////////////////

.btn-primary {
  background: $brand-primary;
  color:#fff;
  &:hover {
    background: lighten($brand-primary, 10%);
    color:#fff;
  }
  &:after {
    background-color:$brand-primary;
  }
  &.transparent {
    background:transparent;
    border:3px solid $brand-primary;
    color:$brand-primary;
    &:hover {
      background:$brand-primary;
      color:white;
    }
  }
  &.gradient {
		background: darken($brand-primary, 15%);
		background: linear-gradient(145deg, $brand-primary 0%, darken($brand-primary,15%) 100%);
    &:hover {
	  	background: linear-gradient(145deg, $brand-primary 0%, darken($brand-primary,15%) 100%);
    }
	}
}

// Secondary
/////////////////

.btn-secondary {
  background: $brand-secondary;
  &:hover {
    background: lighten($brand-secondary, 10%);
  }
  &:after {
    background-color:$brand-secondary;
  }
  &.transparent {
    background:transparent;
    border:3px solid $brand-secondary;
    color:$brand-secondary;
    &:hover {
      background:$brand-secondary;
      color:white;
    }
  }
  &.gradient {
		background: $brand-secondary;
		background: linear-gradient(145deg, $brand-secondary 0%, darken($brand-secondary,15%) 100%);
    &:hover {
	  	background: linear-gradient(145deg, $brand-secondary 0%, darken($brand-secondary,15%) 100%);
    }
	}
}

// Accent
/////////////////

.btn-accent {
  background: $accent;
  &:hover {
    background: darken($accent, 5%);
  }
  &:after {
    background-color:$accent;
  }
  &.transparent {
    background:transparent;
    border:3px solid $accent;
    color:$accent;
    &:hover {
      background:$accent;
      color:white;
    }
  }
  &.gradient {
		background: $accent;
		background: linear-gradient(145deg, $accent 0%, lighten($accent,15%) 100%);
    &:hover {
	  	background: linear-gradient(145deg, $accent 0%, darken($accent,15%) 100%);
    }
	}
}

// Light
/////////////////

.btn-light {
  background: $light;
  color:$dark !important;
  &:hover {
    background: darken($light, 5%);
    color:$dark !important;
  }
  &:after {
    background-color:$light;
  }
  &.transparent {
    background:transparent;
    border:3px solid $light;
    color:$light;
    &:hover {
      background:$light;
      color:$dark;
    }
  }
  &.gradient {
		background: $light;
		background: linear-gradient(145deg, $light 0%, lighten($light,15%) 100%);
    &:hover {
	  	background: linear-gradient(145deg, $light 0%, darken($light,15%) 100%);
    }
	}
}

// Dark
/////////////////

.btn-dark {
  background: $dark;
  color:white;
  &:hover {
    background: lighten($dark, 10%);
  }
  &:after {
    background-color:$dark;
  }
  &.transparent {
    background:transparent;
    border:3px solid $dark;
    color:$dark;
    &:hover {
      background:$dark;
      color:white;
    }
  }
  &.gradient {
		background: $dark;
		background: linear-gradient(145deg, $dark 0%, lighten($dark,15%) 100%);
    &:hover {
	  	background: linear-gradient(145deg, $dark 0%, darken($dark,15%) 100%);
    }
	}
}

// White
/////////////////

.btn-white {
  background: white;
  color:black !important;
  &:hover {
    background: darken(white, 5%);
    color:black !important;
  }
  &:after {
    background-color:$white;
  }
  &.transparent {
    background:transparent;
    border:3px solid white;
    color:white !important;
    &:hover {
      background:white;
      color:black;
    }
  }
}

// Black
/////////////////

.btn-black {
  background: black;
  color:white;
  &:hover {
    background: lighten(black, 10%);
  }
  &:after {
    background-color:$white;
  }
  &.transparent {
    background:transparent;
    border:3px solid black;
    color:black;
    &:hover {
      background:black;
      color:white;
    }
  }
  &.gradient {
		background: #000;
		background: linear-gradient(145deg, #000 0%, lighten(#000,15%) 100%);
    &:hover {
	  	background: linear-gradient(145deg, #000 0%, darken(#000,15%) 100%);
    }
	}
}

.btn-gradient {
  .btn-primary {
    &:not(.transparent) {
      background: $brand-primary;
		  background: linear-gradient(145deg, $brand-primary 0%, lighten($brand-primary,15%) 100%);
    }
    &:hover {
	  	background: linear-gradient(145deg, $brand-primary 0%, darken($brand-primary,15%) 100%);
    }
  }
  .btn-secondary {
    &:not(.transparent) {
      background: $brand-secondary;
		  background: linear-gradient(145deg, $brand-secondary 0%, lighten($brand-secondary,15%) 100%);
    }
    &:hover {
	  	background: linear-gradient(145deg, $brand-secondary 0%, darken($brand-secondary,15%) 100%);
    }
  }
  .btn-accent {
    &:not(.transparent) {
      background: $accent;
		  background: linear-gradient(145deg, $accent 0%, lighten($accent,15%) 100%);
    }
    &:hover {
	  	background: linear-gradient(145deg, $accent 0%, darken($accent,15%) 100%);
    }
  }
  .btn-light {
    &:not(.transparent) {
      background: $light;
		  background: linear-gradient(145deg, $light 0%, lighten($light,15%) 100%);
    }
    &:hover {
	  	background: linear-gradient(145deg, $light 0%, darken($light,15%) 100%);
    }
  }
  .btn-dark {
    &:not(.transparent) {
      background: $dark;
		  background: linear-gradient(145deg, $dark 0%, lighten($dark,15%) 100%);
    }
    &:hover {
	  	background: linear-gradient(145deg, $dark 0%, darken($dark,15%) 100%);
    }
  }
  .btn-black {
    &:not(.transparent) {
      background: #000;
		  background: linear-gradient(145deg, #000 0%, lighten(#000,15%) 100%);
    }
    &:hover {
	  	background: linear-gradient(145deg, #000 0%, darken(#000,15%) 100%);
    }
  }
}

.btn:link,
.btn:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 15px 40px;
    display: inline-block;
    border-radius: 100px;
    transition: all .2s;
    position: relative;
}

.btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}


.btn::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all .4s;
}



.btn:hover::after {
    transform: scaleX(1.3) scaleY(1.5);
    opacity: 0;
}



