// Container Fluid Adjustments
.container-fluid {
  padding-left: 25px;
  padding-right: 25px;
  @include media-breakpoint-up(lg) {
    padding-left: 75px;
    padding-right: 75px;
  }
  @include media-breakpoint-up(xxxl) {
    padding-left: 150px;
    padding-right: 150px;
  }
  @media(screen and min-width:2400px) {
    padding-left:200px;
    padding-right:200px;
  }
  @media(screen and min-width:3000px) {
    padding-left:350px;
    padding-right:350px;
  }
}
.container {
  max-width:1200px;
  padding-left: 25px;
  padding-right: 25px;
  margin-left:auto;
  margin-right:auto;
}
